<div class="login-container">
  <h2>Login</h2>
  <form (ngSubmit)="onSubmit()">
    <div>
      <label for="email">Email:</label>
      <input type="email" id="email" [(ngModel)]="email" name="email" required>
    </div>
    <div>
      <label for="password">Password:</label>
      <input type="password" id="password" [(ngModel)]="password" name="password" required>
    </div>
    <button type="submit">Login</button>
  </form>
  <p>Don't have an account? <a routerLink="/register">Register here</a></p>
</div>
