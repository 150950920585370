<div class="my-data-container">
    <h2>My Data</h2>
    <form (ngSubmit)="onSubmit()">
      <div>
        <label for="email">Email:</label>
        <input type="email" id="email" [(ngModel)]="user.email" name="email" required disabled>
      </div>
      <div>
        <label for="firstName">First Name:</label>
        <input type="text" id="firstName" [(ngModel)]="user.firstName" name="firstName" required>
      </div>
      <div>
        <label for="lastName">Last Name:</label>
        <input type="text" id="lastName" [(ngModel)]="user.lastName" name="lastName" required>
      </div>
      <button type="submit">Update</button>
    </form>
  </div>
  