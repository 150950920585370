import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap, switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private apiUrl = 'https://directus.superbay.de';  // URL zu Ihrer Directus-API
  private token: string | null = null;
  private refreshToken: string | null = null;

  constructor(private http: HttpClient) { }

  login(email: string, password: string): Observable<any> {
    return this.http.post<any>(`${this.apiUrl}/auth/login`, { email, password }).pipe(
      tap(response => {
        this.token = response.data.access_token;
        this.refreshToken = response.data.refresh_token;
        if (this.token) {
          localStorage.setItem('authToken', this.token);  // Token im lokalen Speicher speichern
        }
        if (this.refreshToken) {
          localStorage.setItem('refreshToken', this.refreshToken);  // Refresh-Token im lokalen Speicher speichern
        }
      })
    );
  }

  register(email: string, password: string, firstName: string, lastName: string): Observable<any> {
    const user = {
      email: email,
      password: password,
      first_name: firstName,
      last_name: lastName
    };
    return this.http.post<any>(`${this.apiUrl}/users`, user);
  }

  logout(): void {
    this.token = null;
    this.refreshToken = null;
    localStorage.removeItem('authToken');
    localStorage.removeItem('refreshToken');
  }

  isLoggedIn(): boolean {
    return !!this.token || !!localStorage.getItem('authToken');
  }

  getToken(): string | null {
    return this.token || localStorage.getItem('authToken');
  }

  refreshTokenRequest(): Observable<any> {
    const storedRefreshToken = this.refreshToken || localStorage.getItem('refreshToken');
    if (!storedRefreshToken) {
      return throwError('No refresh token available');
    }
    return this.http.post<any>(`${this.apiUrl}/auth/refresh`, { refresh_token: storedRefreshToken }).pipe(
      tap(response => {
        this.token = response.data.access_token;
        if (this.token) {
          localStorage.setItem('authToken', this.token);  // Aktualisierten Token im lokalen Speicher speichern
        }
      }),
      catchError(error => {
        this.logout();
        return throwError(error);
      })
    );
  }

  getUserData(): Observable<any> {
    const token = this.getToken();
    return this.http.get<any>(`${this.apiUrl}/users/me`, {
      headers: { Authorization: `Bearer ${token}` }
    }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }

  updateUserData(user: any): Observable<any> {
    const token = this.getToken();
    console.log('Update User Data:', user); // Log die Daten
    console.log('Token:', token); // Log den Token
    return this.http.patch<any>(`${this.apiUrl}/users/me`, user, {
      headers: { Authorization: `Bearer ${token}` }
    }).pipe(
      catchError(error => {
        console.error('Update Error:', error); // Log den Fehler
        return throwError(error);
      })
    );
  }
  



  updateUserData2(user: any): Observable<any> {
    const token = this.getToken();
    return this.http.patch<any>(`${this.apiUrl}/users/me`, user, {
      headers: { Authorization: `Bearer ${token}` }
    }).pipe(
      catchError(error => {
        return throwError(error);
      })
    );
  }
}
