import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.css']
})
export class RegisterComponent {
  email: string = '';
  password: string = '';
  firstName: string = '';
  lastName: string = '';

  constructor(private authService: AuthService, private router: Router) { }

  onSubmit(): void {
    this.authService.register(this.email, this.password, this.firstName, this.lastName).subscribe(
      () => {
        this.router.navigate(['/login']);  // Bei erfolgreicher Registrierung zur Anmeldeseite navigieren
      },
      error => {
        console.error('Registration failed', error);
      }
    );
  }
}
