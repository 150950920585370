<div class="register-container">
    <h2>Register</h2>
    <form (ngSubmit)="onSubmit()">
      <div>
        <label for="email">Email:</label>
        <input type="email" id="email" [(ngModel)]="email" name="email" required>
      </div>
      <div>
        <label for="password">Password:</label>
        <input type="password" id="password" [(ngModel)]="password" name="password" required>
      </div>
      <div>
        <label for="firstName">First Name:</label>
        <input type="text" id="firstName" [(ngModel)]="firstName" name="firstName" required>
      </div>
      <div>
        <label for="lastName">Last Name:</label>
        <input type="text" id="lastName" [(ngModel)]="lastName" name="lastName" required>
      </div>
      <button type="submit">Register</button>
    </form>
  </div>
  
