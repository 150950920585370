import { Component, OnInit } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-my-data',
  templateUrl: './my-data.component.html',
  styleUrls: ['./my-data.component.css']
})
export class MyDataComponent implements OnInit {
  user: any = {};

  constructor(private authService: AuthService) { }

  ngOnInit(): void {
    this.authService.getUserData().subscribe(
      data => {
        this.user = data;
      },
      error => {
        console.error('Error fetching user data', error);
      }
    );
  }

  onSubmit(): void {
    this.authService.updateUserData(this.user).subscribe(
      response => {
        console.log('User data updated successfully');
      },
      error => {
        console.error('Error updating user data', error);
      }
    );
  }
}
